<template>
  <div class="vps-detail-view">
    <transition name="bubble">
      <page-block class="table">
        <main-card>
          <div class="addons">
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('name') }}</div>
              <div class="addons__value standart-text">{{ tariff.name }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('id') }}</div>
              <div class="addons__value standart-text">{{ tariff.id }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('projectid') }}</div>
              <div class="addons__value standart-text">{{ tariff.project_id }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('networkid') }}</div>
              <div class="addons__value standart-text">{{ tariff.network_id }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('pool') }}</div>
              <div class="addons__value standart-text">
                {{ !!tariff.subnetpool_id ? tariff.subnetpool_id : 'Нет' }}
              </div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('ip') }}</div>
              <div class="addons__value standart-text">{{ 'IPv' + tariff.ip_version }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('cidr') }}</div>
              <div class="addons__value standart-text">{{ tariff.cidr }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('poolip') }}</div>
              <div class="addons__value standart-text">
                {{
                  'Начало ' +
                  tariff.allocation_pools[0].start +
                  ' - ' +
                  'Конец ' +
                  tariff.allocation_pools[0].end
                }}
              </div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('gateway') }}</div>
              <div class="addons__value standart-text">{{ tariff.gateway_ip }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('dhcp') }}</div>
              <div class="addons__value standart-text">{{ tariff.enable_dhcp ? 'Да' : 'Нет' }}</div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('dns') }}</div>
              <div class="addons__value standart-text">
                <div class="addons__dns">
                  <div class="addons">{{ tariff.dns_nameservers[0] }}</div>
                  <div class="addons">{{ tariff.dns_nameservers[1] }}</div>
                </div>
              </div>
            </div>
            <div class="addons__item">
              <div class="addons__label standart-title">{{ $t('price') }}</div>
              <div class="addons__value standart-text">
                <div class="addons__dns">
                  <div class="addons">{{ $n(getCurrentPrice, 'currency') }} / час</div>
                  <div class="addons">{{ $n(getCurrentPrice * 24, 'currency') }} / день</div>
                  <div class="addons">{{ $n(getCurrentPrice * 24 * 30, 'currency') }} / месяц</div>
                </div>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import { OPTariff } from '@/models/OP/OPTariff';
import providerChange from '@/mixins/providerChange';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
export default {
  name: 'SubnetPublicView',
  mixins: [providerChange],
  props: {
    tariff: {
      type: Object,
      required: true,
      validator: obj => obj instanceof OPTariff,
    },
  },
  data() {
    return {};
  },
  computed: {
    instance() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.serverId) || '';
    },
    networkPrefix() {
      return this.tariff.cidr.split('/')[1];
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
    getCurrentPrice() {
      return +this.getPrice.find(x => x.intname === `network_${this.networkPrefix}`)?.[dev];
    },
    // getCurrentPrice() {
    //   return this.getCurrentPrice('internet');
    // },
    subnets() {
      return this.$store.state.moduleStack.subnets.filter(x => x.network_id === this.tariff.id);
    },
    diskId() {
      return this.tariff.id;
    },
  },
  methods: {
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    // getCurrentPrice(item) {
    //   return +this.getPrice.find(x => x.intname === `item`)?.[dev];
    // },
  },
};
</script>

<i18n>
  {
    "ru": {
        "name": "Имя",
        "id": "ID",
        "projectid": "ID проекта",
      "network": "Имя сети",
      "networkid": "ID сети",
      "pool": "Пул подсети",
      "price": "Цена",
      "ip": "Версия IP",
      "cidr": "CIDR",
      "poolip": "Пулы назначения IP",
      "gateway": "IP шлюза",
      "dhcp": "Разрешен DHCP",
      "additional": "Дополнительные маршруты",
      "dns": "DNS-серверы"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.table {
  margin-top: 0.5rem;
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 160px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {
  &__dns{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        //border-top: 1px solid var(--border);
        //transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
<script setup></script>
